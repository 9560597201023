@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("../node_modules/mapbox-gl/dist/mapbox-gl.css");
@import url("../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css");
//@import url("../node_modules/@mapbox/mapbox-gl-geocoder");
@import url("../node_modules/leaflet/dist/leaflet.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i");



html,
body,
#root,
.App {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

input{
  &:focus{
    outline: none;
  }
}button{
  &:focus{
    outline: none;
  }
}
.router-container {
  //color: #ffffff;
  height: 100%;
  width: -webkit-fill-available;
  display: flex;
  flex-flow: column;
  
}

* {
  font-family: "Roboto", sans-serif;

}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .app-header {
    height: 108px;

    .h-left {
      float: left;
      margin-left: 40px;
      margin-top: 30px;

      .logout {
      }

      .h-title {
        font-size: 20px;
        color: #ffffff;
        margin-left: 20px;
        cursor: default;
        position: relative;
        top: 2px;
      }
    }
  }
  .left-banner-component-container {
    float: left;
  }
  .float-r {
    float: right;
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.forgot-password-modal-contant {
  .txt-field {
    input,
    label {
      //color: #051640 !important;
    }
  }
}

.forgot-password-template {
  color: rgb(5, 22, 64);

  .forgot-password-modal-contant {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 13px !important;

    .close-btn {
      text-align: left;
      width: 15px;
      cursor: pointer;
    }
  }
}

.left-banner {
  .campaigns-list span {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.5) !important;
    text-align: left;
    letter-spacing: 0.03em;
    cursor: pointer;
  }

  .item-pressed span {
    color: rgba(255, 255, 255, 1) !important;
  }
}

.round-left {
  border-radius: 0px 0px 0px 11px;
}

.round-right {
  border-radius: 0px 0px 11px 0px;
}

.main-header {
  width: 100%;
  height: 82px;
  background-color: rgba(246, 246, 247, 1);
  display: flex;
  align-items: center;
  flex-direction: row;

  .campaign-header {
    position: relative;
    width: 30%;
    height: 55%;
    float: left;
    display: flex;
    align-items: center;
    margin-left: 3%;
    font-size: 17pt;
    font-weight: bold;
    color: rgba(45, 50, 71, 1);
  }

  .employee-name {
    position: relative;
    min-width: 200px;
    height: 55%;
    float: right;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    flex-direction: row;

    .employee-icon {
      float: left;
      background-image: url("assets/Group_2.svg");
      height: 46px;
      width: 43px;
    }

    .employee-text {
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
      width: 63%;
      margin-left: 8%;
      font-size: 14pt;
      font-weight: 500;
      color: rgba(45, 50, 71, 1);
      text-align: left;
      letter-spacing: 0.03em;
    }
  }
}

.hide-elm {
  position: absolute !important;
  //width: 0px !important;
  filter: alpha(opacity=0) !important;
  opacity: 0 !important;
  outline: none !important;
  background: transparent !important;
  cursor: pointer !important;
  //display: block !important;
  //height: 0px !important;
  z-index: -999 !important;
}

.bold {
  font-weight: bold;
}

.mb1 {
  margin-bottom: 0.5rem;
}

.mb2 {
  margin-bottom: 1rem;
}

/*
.mb3 {
  margin-bottom: 2rem;
}

.mb4 {
  margin-bottom: 4rem;
}
*/

.ml2 {
  margin-left: 1rem;
}

._gray {
  color: rgba(119, 123, 139, 1);
}
._orange {
  color: rgba(247, 181, 0, 1);
}

._black {
  color: rgba(45, 50, 71, 1);
}

._green {
  color: rgba(65, 233, 146, 1);
}

.un-pddr {
  padding-right: 0px !important;
}

.un-pddl {
  padding-left: 0px !important;
}
/*.mapboxgl-canvas{
  height: 100% !important;
  width: 100% !important;
}*/

.mapboxgl-ctrl-geocoder {
  border-radius: 7px !important;
  //top: 50px;
  .suggestions li {
    color: rgba(0, 0, 0, 0.8);
  }
}

.hide-geocoder .mapboxgl-ctrl-geocoder {
  display: none !important;
}
.mapboxgl-canvas {
  left: 0 !important;
}

.flx {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-space-between {
  justify-content: space-between;
}

.zz-btn {
  cursor: pointer;
  padding: 0.4rem 1.6rem;
  width: fit-content;
  border: 1px solid #fbc13b;
  border-radius: 7px;
  color: #fbc13b;
  font-weight: 500;
}

.mb5 {
  margin-bottom: 5px;
}

.add-employee-template {

}

#geocoder-country-container {
  .mapboxgl-ctrl-geocoder {
    box-shadow: unset !important;
    border-radius: 6px !important;
    border: 1px solid rgb(151, 151, 151) !important;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}

.err-msg {
  padding: 5px 0px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(255, 91, 120);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  text-align: left;
}

.radio-buttons-container label {
  margin-bottom: 0px !important;
}

.tl-edit-container .section-list > div:hover {
  background-color: rgb(251, 193, 59) !important;
}

#map-create-layout .ch-no-belt {
  label {
    margin: 0px !important;
    > span {
      padding: 0px 5px 0px 0px !important;
      color: white;
      font-weight: 100;
    }
  }
}
.map-layout-create-mode {
  .mapboxgl-ctrl-top-right {
    height: 100%;
    .mapboxgl-ctrl-group {
      position: absolute;
      bottom: 25px;
      right: 0;
    }
    .mapboxgl-ctrl-geocoder {
      top: 55px;
      right: 0px
    }
  }
}

  .mapboxgl-ctrl-top-right {
    height: 100%;

    .mapboxgl-ctrl-group {
      position: absolute;
      bottom: 25px;
      right: 340px
    }

    .mapboxgl-ctrl-geocoder {
      top: 55px;
      right: 340px
    }
  }

.half-opacitiy {
  opacity: 0.5;
}

.un-pointer {
  cursor: unset !important;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.strong-gray-scroller::-webkit-scrollbar {
  width: 6px;
}

.strong-gray-scroller::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #73757f;
}

.strong-gray-scroller::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 3px;
}

.gray-scroller::-webkit-scrollbar {
  width: 6px;
}

.gray-scroller::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 3px;
}

.bdt35 {
  padding-top: 35px;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mapboxgl-control-container {
  .mapboxgl-ctrl-bottom-left {
    .mapboxgl-ctrl {
      margin: 0px 0 10px 220px !important;
    }
  }
}
