.employee-main-wrapper {
  width: 75%;
  margin-left: auto;
  padding-right: 50px;
  .employee-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 1062px;
    margin: 0 auto;

    .employees-title-strip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 3rem 15px 0px 15px;

      .employees-title-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        position: relative;
        top: -1.5rem;

        .employees-title-icon-strip {
          display: flex;
          .employees-icon {
            width: 14px;
            padding-right: 5px;
          }
          .employees-title {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            color: #2d3247;
            letter-spacing: 0.03em;
          }
        }

        .employees-list {
          font-size: 13px;
          font-weight: 300;
          color: rgba(45, 50, 71, 1);
          text-align: left;
          letter-spacing: 0.03em;
        }
      }
      .add-employee-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-height: 36px;
        .add-employee-btn {
          font-size: 14px;
          letter-spacing: 0.03em;
        }
      }
    }

    .separator-line {
      background-color: white;
      width: 100%;
      align-self: flex-end;
      margin: 0;
      display: flex !important;
      padding-bottom: 2.5rem;
    }

    .back-office-container {
      width: 100%;
      height: 40%;
      display: flex;
      align-self: center;
      background-color: rgba(246, 246, 247, 1);
      box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.08);
      flex-direction: column;

      .back-office-header-text {
        width: 20%;
        height: 15%;
        font-size: 15pt;
        color: rgba(45, 50, 71, 1);
        text-align: left;
        letter-spacing: 0.02em;
        font-weight: bold;
        margin-left: 1.5%;
        margin-top: 1.5%;
      }

      .back-office-fields-container {
        width: 93%;
        height: 10%;
        display: flex;
        align-self: center;
        flex-direction: row;
        padding-bottom: 2%;

        .back-office-name-field {
          width: 10%;
          height: 100%;
          flex-direction: row;
          display: flex;

          .back-office-name-text {
            font-size: 18px;
            font-weight: bold;
            color: rgba(45, 50, 71, 1);
            text-align: left;
            letter-spacing: 0.03em;
            float: left;
            align-self: center;
          }

          .back-office-arrow-icon {
            background-image: url("../../../../assets/Triangle.svg");
            background-repeat: no-repeat;
            width: 10%;
            height: 100%;
            float: right;
            background-position: center;
            margin-left: 4%;
            margin-top: 2%;
          }
        }

        .back-office-phone-field {
          width: 20%;
          height: 100%;
          flex-direction: row;
          display: flex;
          margin-left: 1.5%;

          .back-office-phone-text {
            font-size: 18px;
            font-weight: bold;
            color: rgba(45, 50, 71, 1);
            text-align: left;
            letter-spacing: 0.03em;
            float: left;
            align-self: center;
            margin-left: 30%;
          }

          .back-office-arrow-icon {
            background-image: url("../../../../assets/Triangle.svg");
            background-repeat: no-repeat;
            width: 10%;
            height: 100%;
            float: right;
            background-position: center;
            margin-top: 1%;
          }
        }

        .back-office-email-field {
          width: 20%;
          height: 100%;
          flex-direction: row;
          display: flex;
          margin-left: 1.5%;

          .back-office-email-text {
            font-size: 18px;
            font-weight: bold;
            color: rgba(45, 50, 71, 1);
            text-align: left;
            letter-spacing: 0.03em;
            float: left;
            align-self: center;
            margin-left: 40%;
          }

          .back-office-arrow-icon {
            background-image: url("../../../../assets/Triangle.svg");
            background-repeat: no-repeat;
            width: 10%;
            height: 100%;
            float: right;
            background-position: center;
            margin-top: 1%;
          }
        }
      }

      .back-office-workers-container,
      .field-workers-container {
        width: 100%;
        justify-content: center;
        display: block;
      }
    }
    .no-employee-border{
      height: 40px;
      margin-top: 100px;
      .no-employee-icon{
        margin-bottom: 5px;
      }
      .no-employee-header{
        color: rgba(97, 97, 97, 1);
      }
      .no-employee-txt{
        color: rgba(255, 197, 0, 1);
        cursor: pointer;

        &:before{
          content: 'Press on button  ';
          color: rgba(97, 97, 97, 1);
        }
        &:after{
          content: "   button at the top of the page.";
          color: rgba(97, 97, 97, 1);
        }
      }
    }
  }
}

.filter-bubble-wrapper {
  position: absolute;
  left: 120px;
  top: 45px;
  z-index: 1;
  .filter-bubble-grid {
    font-size: 14px;
    width: 650px;
    height: 192px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.22);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    .filter-label-grid-area {
      grid-area: 1 / 1 / 2 / 2;
    }
    .filter-field-grid-area {
      grid-area: 2 / 1 / 3 / 2;
    }
    .irs-certification-checkbox-grid-area {
      grid-area: 3 / 2 / 4 / 3;
    }
    .larviciding-certification-checkbox-grid-area {
      grid-area: 2 / 3 / 3 / 4;
    }
    .enthomology-certification-checkbox-grid-area {
      grid-area: 3 / 3 / 4 / 4;
    }
    .apply-button-grid-area {
      margin-left: auto;
      grid-area: 4 / 3 / 5 / 4;
    }
  }
}

/*.filter-bubble:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.625em solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -0.625em;
  margin-top: -0.625em;
}*/
