.task-container-card{
  width: 100%;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: calc(180px + 30px + 15px);
  .send-results-button{
    color: #fbc13b;
    margin-left: 10px;
    white-space: nowrap;
    font-size: 1.5em;
    border: 1px solid #fbc13b;
    border-radius: 5px;
    height: 40px;
    padding: 0 5px;
    cursor: pointer;
  }
  .add-task-container {
    display: flex;
    justify-content: flex-end;
    min-height: 36px;
    .add-task-btn {
      font-size: 14px;
      letter-spacing: 0.03em;
    }
  }
}

.task-card-element {
  // flex-wrap: nowrap | wrap | wrap-reverse;
}
.task-vertical-dotted-line {
  height: 30px;
  position: relative;
  left: 3px;
  width: fit-content;
  margin: 2px 0px;
}

.task-vertical-dotted-line-1 {
  border-left: 1px dotted rgb(102, 101, 101);
}

.task-vertical-dotted-line-2 {
  border-left: 1px dotted rgb(247, 181, 0);
}

.task-vertical-dotted-line-3 {
  border-left: 1px dotted rgb(125, 211, 42);
}

.task-vertical-dotted-line.azure {
  border-left: 1px dotted rgb(50, 197, 255);
}

.task-vertical-dotted-line.purple {
  border-left: 1px dotted rgb(186, 134, 233);
}

.task-vertical-dotted-line.yellow {
  border-left: 1px dotted rgb(255, 234, 18);
}

.task-vertical-dotted-line.green {
  border-left: 1px dotted rgb(65, 233, 146);
}

.task-vertical-dotted-line.orange {
  border-left: 1px dotted #f58023;
}

.task-data-from-field {
  display: flex;
  flex-direction: column;
  color: rgba(102, 101, 101, 1);
  .sprayer-phone-number {
    text-align: left;
  }
  .data-tab {
    margin-right: 20px;
  }
  .data-item {
    display: flex;
    align-items: center;
    line-height: 1;
    .data-shape-1 {
      height: 19px;
      width: 7px;
      border-radius: 1px;
    }
    .data-shape-2 {
      height: 7px;
      width: 7px;
      border-radius: 4px;
    }
    .data-shape-1.azure {
      background-color: rgb(50, 197, 255);
    }
    .data-shape-1.purple {
      background-color: rgb(186, 134, 233);
    }
    .data-shape-1.yellow {
      background-color: rgb(255, 234, 18);
    }
    .data-shape-2.green {
      background-color: rgb(65, 233, 146);
    }
    .data-shape-2.orange {
      background-color: rgb(245, 128, 35);
    }
    .data-shape-2.yellow {
      background-color: rgb(255, 234, 18);
    }
    span {
      // font-size: 15px;
      color: rgba(102, 101, 101, 1);
      text-align: left;
      letter-spacing: 0.03em;
      padding-left: 5px;
    }
  }
  .data-item-2 {
    position: relative;
    top: -4px;
  }
  .data-item-2-icon {
    position: relative;
    right: 10px;
  }
}

.not-found-task-dialog {
  height: 200px;
  width: 252px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .desc{
    font-size: 1.2em;
  }
  .dialog-buttons {
    display: flex;
    margin-top: auto;
    margin-bottom: 40px;
    width: 100%;
    justify-content: space-around;

    .send-button{
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #fbc13b;
      border-radius: 5px;
      color:#fbc13b;
    }
    .cancel-button{
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid red;
      border-radius: 5px;
      color:red;
    }
  }
}

.assignment {
  margin-bottom: 20px;
}

.assignment:last-child {
  margin-bottom: -20px;
}

.task-result-dialog {

  .task-result-dialog-padding {
    width: 810px;
    height: 820px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 45px;

    .task-dialog-header{
      display: flex;
      width: 100%;
      justify-content: space-between;

      .dialog-header {
        height: 14px;
        width: 128px;
        font-size: 25px;
        color: rgba(45, 50, 71, 1);
        letter-spacing: 0.03em;
      }
    }
    .stepper-dots{
      display: flex;
      width: 300px;
      justify-content: center;
      align-self: center;
      .dot{
        background: grey;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        cursor: pointer;
        margin: 0 3px;
      }
      .selected-dot{
        background: blue;
      }
    }

    .dialog-result-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 20px;
      padding: 15px;


      .task-result-field {
        width: 250px;

        .field-dialog-input {
          border-radius: 6px;
          border: 1px solid;
          padding: 6px 5px;
          width: 100%;
        }

        .field-dialog-label {
          font-size: 15px;
          font-weight: 500;
          color: rgba(45, 50, 71, 1);
        }
        .form-validate-msg{
          color: red;
          font-size: 13px;
        }
      }
    }

    .set-results-btn {
      display: flex;
      justify-content: space-between;

      .continue-btns{
        display: flex;
        width: 280px;
        justify-content: space-around;
      }
      .btns{
        width: 108px;
        border-radius: 4px;
        text-align: center;
        padding: 3px 5px;
      }
      .dialog-done {
        border: 1px solid #58c579;
        color: #58c579;
        cursor: pointer;
        font-weight: bold;
      }

      .dialog-next{
        width: 150px;
        border: 1px solid rgba(251, 193, 59, 1);
        color: rgba(251, 193, 59, 1);
        cursor: pointer;

      }
      .back {
        cursor: pointer;
        border: 1px solid rgb(251, 37, 0);
        color: rgb(251, 37, 0);
      }

      .inactive {
        border: 1px solid #979797;
        color: #979797;
      }
    }
  }
}

.form-validate-msg-cal{
  color: red;
  font-size: 17px;
  padding: 30px 0;
}
