.login-layout {
  height: 100%;
  justify-content: center;
  background-color: rgba(45, 50, 71, 1);
  display: flex;

  .zzapp-logo-text {
    background-image: url("../../assets/zzapp_text.svg");
    width: 12rem;
    height: 45px;
    position: absolute;
    bottom: 2rem;
    background-size: 100%;
    left: 1.5rem;
    background-repeat: no-repeat;
  }

  .zzapp-logo-corner {
    align-self: end;
    background-repeat: no-repeat;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }

  .zzapp-logo-fly {
    background-image: url("../../assets/fly_icon.svg");
    position: relative;
    background-repeat: no-repeat;
    height: 90px;
    width: 98px;
  }
}
