.success-template {
  .close-btn-container {
    display: flex;
    justify-content: flex-start;
    .close-btn {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .modal-title {
    padding: 25px 0px 15px 45px;
    font-weight: bold;
  }

  .add-employee-title {
    font-size: 25px;
    font-weight: 500;
    color: rgb(45, 50, 71);
    text-align: left;
    letter-spacing: 0.03em;
  }

  .tab-content {
    display: flex;
    padding-top: 40px;
    padding-left: 30px;
  }

  .inactive-btn {
    height: 32px;
    width: 108px;
    border: 1px solid rgba(151, 151, 151, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: not-allowed;
    span {
      font-size: 16px;
      font-weight: 300;
      color: rgb(94, 94, 94);
      letter-spacing: 0.03em;
    }
  }

  .active-btn {
    height: 32px;
    width: 160px;
    border: 1px solid rgba(251, 193, 59, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 500;
      color: rgba(251, 193, 59, 1);
      letter-spacing: 0.03em;
    }
  }

  .continue-col {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
  }

  .txt-field {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
  }

  .checkbox-field {
    margin-bottom: 10px;
  }

  .modal-description {
    margin-right: 30px;
  }

  .checkboxs-container {
    margin-top: 30px;
  }

  .modal-done-txt {
    display: flex;
    flex-direction: column;
    div {
      font-size: 16px;
      font-weight: 500;
      color: rgb(45, 50, 71);
      text-align: left;
      letter-spacing: 0.03em;
    }
  }

  .add-employee-modal-done-contant {
    position: absolute;
    flex: 0 0 50%;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 14px;
    padding: 45px 20px 30px 40px;
    max-width: 479px;
    width: 100%;
    .modal-title {
      font-size: 25px;
      text-align: left;
      padding: 0px !important;
    }
    .ok-btn {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      padding-top: 50px;
      .active-btn {
        margin: 0px !important;
      }
    }
  }

  .add-employee-modal-next-contant {
    position: absolute;
    flex: 0 0 50%;
    background-color: white;
    padding: 15px;
    border-radius: 14px;
    padding: 45px 40px 30px 40px;
    max-width: 540px;
    width: 100%;
    .modal-title {
      font-size: 25px;
      text-align: left;
      padding: 0px !important;
    }
    .ok-btn {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      padding-top: 20px;
      .active-btn {
        margin: 0px !important;
      }
    }
  }
}
