.download-scan{
  cursor: pointer;
  height: 40px;
  margin-top: 5px;

}
.downloaded-scan{
  cursor: default;
  pointer-events: none;
  height: 40px;
  margin-top: 5px;

}
.open-village-trap-btn{
  cursor: pointer;
  color: #ba86e9;
  //border-bottom: 1px solid;
  //width: 110px;
  //margin-bottom: 10px;
  //padding: 0px 10px;
  text-decoration: underline;
  margin-left: 3px;

}
.open-village-sample-btn{
  margin-left: 3px;
  cursor: pointer;
  color: #86dde9;
  //border-bottom: 1px solid;
  //width: 110px;
  //margin-bottom: 10px;
  //padding: 0px 10px;
  text-decoration: underline;
}
.open-sample-btn{
  text-align: left;
  margin-top: 10px;
  color: #86dde9;
  font-weight: bold;
  cursor: pointer;
}