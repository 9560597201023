$liver: rgba(77, 77, 77, 1);
$charcoal: #242837;

.image-analyst-page {
  background-color: #f6f6f7;
  color: $liver;
  .mb-4 {
    margin-bottom: 4rem;
  }
  .mr-4 {
    margin-right: 4rem;
  }

  .info-bar-display-grid {
    height: 80px;
    align-items: center;
    background-color: $charcoal;
    color: white;
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .icon-and-username-grid-area {
      display: flex;
      text-align: left;
      grid-area: 1 / 1 / 2 / 2;
    }
    .logout-grid-area {
      text-align: right;
      grid-area: 1 / 2 / 2 / 3;
    }
    .zzapp-text {
      margin-right: 20px;
      background-image: url("../../../assets/zzapp_text.svg");
      background-size: 100%;
      position: relative;
      height: 25px;
      width: 100.43px;
      background-repeat: no-repeat;
    }
    .logout-container {
      align-text: right;
      display: flex;
      .logout-icon {
        background-image: url("../../../assets/logout.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 16px;
        cursor: pointer;
        height: 18px;
        width: 18px;
      }

      .logout-text {
        white-space: nowrap;
        font-size: 0.9rem;
        color: rgb(255, 49, 80);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }
    }
  }
  .bg {
    margin: 1% 18%;
    box-shadow: 2px 2px 7px 1px #000000;
    .upload-display-grid {
      background-color: white;
      padding: 40px;
      display: grid;
      grid-template-columns: 4fr 1fr;
      grid-template-rows: 4fr 1fr 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 40px;
      text-align: left;
      .upload-form-grid-area {
        grid-area: 1 / 1 / 2 / 2;
        .date-reported {
          text-align: right;
          position: relative;

          .date-reported-data {
            font-weight: bold;
            font-size: 1.25rem;
            padding-right: 18px;
            cursor: pointer;
          }
          .data-picker{
            position: absolute;
            top: -110px;
            right: 140px;
          }
        }
      }
      .send-button-grid-area {
        text-align: right;
        grid-area: 2 / 1 / 3 / 3;
      }
      .progress-bar-grid-area {
        grid-area: 3 / 1 / 4 / 3;
      }
    }
  }
}
