.campaign-main-wrapper {
    width: 75%;
    margin-left: auto;
    padding-right: 50px;
    overflow-y: auto;
    .campaign-main-container {
      padding-right: 50px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.5);
      max-width: 1062px;
      margin: 0 auto;
  
      .campaigns-title-strip {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 3rem 15px 0px 15px;
  
        .campaigns-title-container {
          display: flex;
          flex-direction: column;
          width: fit-content;
          position: relative;
          top: -1.5rem;
  
          .campaigns-title-icon-strip {
            display: flex;
            .campaigns-icon {
              width: 26px;
              padding-right: 5px;
            }
            .campaigns-title {
              text-align: left;
              font-size: 18px;
              font-weight: bold;
              color: #2d3247;
              letter-spacing: 0.03em;
            }
          }
  
          .campaigns-list {
            font-size: 13px;
            font-weight: 300;
            color: rgba(45, 50, 71, 1);
            text-align: left;
            letter-spacing: 0.03em;
          }
        }
        .add-campaign-container {
          display: flex;
          justify-content: flex-end;
          min-height: 36px;
          .add-campaign-btn {
            font-size: 14px;
            letter-spacing: 0.03em;
          }
        }
      }
  
      .separator-line {
        background-color: white;
        width: 100%;
        align-self: flex-end;
        margin: 0;
        display: flex !important;
        padding-bottom: 2.5rem;
      }

      .campaign-bg-img{
        img{
          height: 10.7rem;
        }
      }
    }
  }