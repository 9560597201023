.create-village-mode {
  .cancelation-bar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 25px;
    left: 50%;
    top: 0;
    width: 630px;
    height: 38px;
    background-color: #f9f9f9;
    border-radius: 0px 0px 11px 11px;
    cursor: auto;
    z-index: 3;
    transform: translate(-50%, 0);;

    .auto-create-btn {
      margin-left: 35px;
      color: #cdcbcbb8;
      cursor: pointer;
    }

    .manual-create-btn {
      color: #cdcbcbb8;
      cursor: pointer;
    }

    .active {
      color: rgba(247, 181, 0, 1);
    }

    .cancel-btn {
      margin-left: auto;
      color: #fb3e56;
      font-weight: 600;
      letter-spacing: 0.4px;
      font-size: 14px;
      cursor: pointer;

      &:before {
        content: url("../../../../assets/news-icons/close-icon.svg");
        margin-right: 3px;
      }
    }
  }

  .bottom-tool-bar {
    border-radius: 11px 0px 0px 0px;
    z-index: 3;
    max-width: 646px;
    bottom: 1px;
    height: 50px;
    width: 370px;
    right: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .bottom-bar-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;


      .village-name-section {
        width: 170px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          color: rgb(45, 50, 71);
          text-align: left;
          letter-spacing: 0.03em;
          font-weight: 500;
          padding-bottom: 5px;
        }
        .input-village-name {
          padding: 0px 5px;
          background-color: transparent;
          height: 35px;
          border: 1px solid #979797;
          border-radius: 5px;
          width: 100%;
          font-size: 16px;
          color: #59585c;
          text-align: left;
          letter-spacing: 0.06em;
        }
      }
      .radio-container {
        display: flex;
        height: 18px;
      }
      .create-btn {
        line-height: 2;
        border: 1px solid #fbc13b;
        border-radius: 11px;
        cursor: pointer;
        height: 35px;
        width: 84px;

        .create-btn-txt {
          font-size: 14px;
          color: rgba(251, 193, 59, 1);
          text-align: center;
          letter-spacing: 0.03em;
          line-height: 2.2;
        }
      }
      .disable-create-btn {
        border: 1px solid rgb(151, 151, 151) !important;
        cursor: not-allowed !important;
        .create-btn-txt {
          color: lightgray !important;
        }
      }
      .delete-path {
        font-size: 16px;
        font-weight: bold;
        color: #fb3e56;
        text-align: left;
        letter-spacing: 0.01em;
        cursor: pointer;
        .delete-txt {
          display: flex;
          justify-content: space-between;
          width: 68px;
        }
      }
    }
  }
  .top-tool-bar {
    position: absolute;
    background-color: #242837;
    border-radius: 0px 0px 11px 11px;
    z-index: 3;
    right: 1px;
    top: 0px;
    display: flex;
    flex-direction: column;
    min-height: 59px;
    width: 270px;
    height: fit-content;
    .village-actions-btns {
      display: flex;
      height: 100%;
    }
    .ch-no-belt {
      width: 120px;
      padding-bottom: 10px;
    }
    .col-6 {
      display: flex;
      justify-content: center;
      .edit-txt-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        .edit-txt {
          padding-left: 5px;
          height: fit-content;
          margin: auto 0;
        }
      }
    }
  }
  .auto-creation-options {
    bottom: 0;
    right: 35%;
    width: 360px;
    height: 79px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    border-top-left-radius: 13px;
    z-index: 10;
    padding: 0 35px;

    .delete-auto-create-village {
      color: red;
      font-weight: 500;
      cursor: pointer;
      &:before {
        content: url("../../../../assets/news-icons/close-icon.svg");
        margin-right: 3px;
      }
    }
    .submit-auto-create {
      color: #fbc13b;
      border: 1.7px solid #fbc13b;
      cursor: pointer;
      border-radius: 5px;
      padding: 5px 30px;
    }
  }
  .create-village-shape{
    position: absolute;
    top: 30%;
    left: 3%;
    background: white;
    padding: 8px;
    border-radius: 10px;
    //width: 120px;

    .draw-map-icon{
      cursor: pointer;
      height: 20px;
      float: right;

    }
    .core-shape{
      color: #f7b500;
    }
    .belt-shape{
      color: #cdcbcbb8;
      cursor: pointer;
    }
    .active{
      color: #f7b500;
      cursor: none;
    }
  }
}