.close-btn-container {
  display: flex;
  justify-content: flex-start;
  .close-btn {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
}

.modal-title-campaign {
  font-weight: bold;
}

.add-campaign-title {
  font-size: 25px;
  font-weight: 500;
  color: rgb(45, 50, 71);
  text-align: left;
  letter-spacing: 0.03em;
  margin-left: 40px;
}

.tab-content {
  display: flex;
  padding-top: 20px;
  padding-left: 30px;
}

.send-campaign-btn {
  margin-bottom: -20px;
}

.inactive-btn {
  height: 32px;
  width: 108px;
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  span {
    font-size: 16px;
    font-weight: 300;
    color: rgb(94, 94, 94);
    letter-spacing: 0.03em;
  }
}

.active-btn {
  height: 32px;
  width: 108px;
  border: 1px solid rgba(251, 193, 59, 1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(251, 193, 59, 1);
    letter-spacing: 0.03em;
  }
}

.continue-col {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}

.txt-field {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
}
.form-style{
  height: 55px !important;
}

.checkbox-field {
  margin-bottom: 10px;
  white-space: nowrap;
}

.modal-description {
  margin-right: 30px;
}
.search-country{
  text-align: left;
  font-weight: bold;
  color: #051640;
}
.controls-title {
  color: rgb(5, 22, 64);
  text-align: left;
  padding-bottom: 5px;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.checkboxs-title {
  padding-top: 20px;
}

.radio-buttons-title {
}

.checkboxs-container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  .col-6 {
    padding-left: 0px;
  }
}
.modal-done-txt {
  display: flex;
  flex-direction: column;
  div {
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 50, 71);
    text-align: left;
    letter-spacing: 0.03em;
  }
}

.add-campaign-modal-done-contant {
  position: absolute;
  flex: 0 0 50%;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 14px;
  padding: 45px 20px 30px 40px;
  max-width: 479px;
  width: 100%;
  .modal-title {
    font-size: 25px;
    text-align: left;
    padding: 0px !important;
  }
  .ok-btn {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 50px;
    .active-btn {
      margin: 0px !important;
    }
  }
}

.add-campaign-modal-next-contant {
  position: absolute;
  flex: 0 0 50%;
  background-color: white;
  padding: 15px;
  border-radius: 14px;
  padding: 45px 40px 30px 40px;
  max-width: 540px;
  width: 100%;
  .modal-title {
    font-size: 25px;
    text-align: left;
    padding: 0px !important;
  }
  .ok-btn {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 20px;
    .active-btn {
      margin: 0px !important;
    }
  }
}

.country-container {
  position: relative;
  max-width: 262px;
  border-radius: 6px;
}

.radio-buttons-container {
  display: flex;
  justify-content: space-between;
  max-width: 225px;
}
.scan-modes{
  width: 325px;
  max-width: 325px;
}
.sample-dips{
  display: flex;
  width: 300px;
  > div{
    margin-right: 10px;
  }
}
