.btm-space {
  margin-bottom: 15px;
  margin-top: 15px;
}
.minimize-arrow-pressed {
  width: 70px !important;
  padding: 0 5px !important;
}

.left-banner {
  width: 210px;
  height: 100%;
  background-color: #242837;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: 0.5s all;

  .header-minimized {
    justify-content: flex-end !important;
    align-items: center;
    height: 33px;
    position: relative;
  }

  .header {
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .zzapp-text {
      background-image: url("../../../assets/zzapp_text.svg");
      background-size: 100%;
      position: relative;
      height: 25px;
      width: 100.43px;
      background-repeat: no-repeat;
    }

    .zzapp-user {
      color: rgb(255, 255, 255);
      margin-top: 5px;
    }

    .zzapp-text-container {
      margin: 0 auto;
      .zzapp-text-minimize-screen {
        background-image: url("../../../assets/Group_13.svg");
        background-size: 100%;
        height: 18px;
        width: 20px;
        margin-right: 3px;
      }
    }

    .minimize-arrow-left {
      background-image: url("../../../assets/arrow-left_-_simple-line-icons.png");
      background-size: 100%;
      position: relative;
      width: 18px;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .minimize-arrow-right {
      background-image: url("../../../assets/arrow-left_-_simple-line-icons_1.png") !important;
      background-repeat: no-repeat;
      width: 18px;
      height: 18px;
      margin-left: 0;
      position: absolute;
    }
  }

  .lower-area-minimized {
    align-items: center !important;
  }

  .upper-area {
    width: 100%;
    margin-top: 20px;
    align-self: center;
    flex-direction: column;
    display: flex;

    .map-container-minimized {
      justify-content: center;
      margin-bottom: 10px;
    }
    .image-analyst-container{
      margin-top: 15px;
    }
    .map-container {
      flex-direction: row;
      display: flex;
      align-items: center;

      .map-icon {
        background-image: url("../../../assets/loc.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 2px;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      .map-icon-pressed {
        background-image: url("../../../assets/loc_1.svg") !important;
      }


      .drone-icon {
        background-image: url("../../../assets/drone-gery.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 5px;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
      .drone-icon-pressed {
        background-image: url("../../../assets/drone-orange.svg") !important;
      }
      .map-icon-minimized {
        height: 25px;
        width: 17px;
        margin-right: 0;
      }

      .map-text {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .map-text-pressed {
        color: rgba(251, 193, 59, 1) !important;
      }

      .map-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }

    .employee-container-minimized {
      justify-content: center;
    }

    .employee-container {
      margin-top: 15px;
      flex-direction: row;
      display: flex;
      align-items: center;

      .employee-icon {
        background-image: url("../../../assets/Combined_Shape_1.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 12px;
        cursor: pointer;
        height: 25px;
        width: 10px;
      }

      .employee-icon-pressed {
        background-image: url("../../../assets/tie.svg") !important;
      }
      .trap-results-icon {
        background-image: url("../../../assets/traps-icon.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 12px;
        cursor: pointer;
        height: 25px;
        width: 10px;
      }

      .trap-results-icon-pressed {
        background-image: url("../../../assets/tie.svg") !important;
      }

      .employee-icon-minimized {
        height: 30px;
        width: 13px;
        margin-right: 0;
        position: relative;
        right: 2px;
      }

      .employee-text {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .employee-text-pressed {
        color: rgba(251, 193, 59, 1) !important;
      }

      .employee-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }
    .campaigns-container-minimized {
      visibility: hidden;
    }

    .campaigns-container {
      flex-direction: column;
      width: 100%;
      margin-top: auto;
      display: flex;

      .campaigns-header {
        margin-top: 25px;
        font-size: 0.9rem;
        color: white;
        text-align: left;
        font-weight: bold;
        letter-spacing: 0.03em;
        margin-bottom: 10px;
      }

      .header-min {
        text-align: center;
        font-size: 1.9rem;
      }
      .is-min-margin {
        margin-left: 0;
      }
      .is-wide-margin {
        margin-left: 10px;
      }
      .campaigns-name-list {
        width: 100%;
        max-height: 37vh;
        overflow-y: scroll;
        box-sizing: content-box;

        .campaigns-text {
          font-size: 0.9rem;
          text-align: left;
          cursor: pointer;
          padding: 5px 3px;
          color: rgba(255, 255, 255, 0.5);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
        .campaigns-pressed {
          font-weight: bold;
          color: white;
        }
        .text-hidden {
          width: 60px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            //overflow: visible;
            //background: #242837;
            //border-radius: 10px;
            //width: 192px;
          }
        }
      }
      .hide-scroll-bar {
        &:hover {
          padding-right: 135px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          .text-hidden {
            background: #242837;
            width: 192px;
          }
        }
      }
      .hide-scroll-bar::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }
  }
  .upper-area-minimized {
    margin-top: 35px;
  }

  .separator-line {
    background-color: white;
    width: 100%;
    align-self: flex-end;
    margin-top: 5%;
  }

  .separator-line-minimized {
    margin-top: 30%;
    margin-bottom: 25%;
  }

  .lower-area {
    width: 100%;
    align-self: center;
    align-items: baseline;
    flex-direction: column;
    display: flex;
    /* margin-top: auto; */
    margin-bottom: 20px;

    .campaign-management-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;

      .campaign-management-icon {
        background-image: url("../../../assets/setemp.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
        cursor: pointer;
        height: 16px;
        width: 16px;
      }

      .campaign-management-icon-pressed {
        background-image: url("../../../assets/setemp_1.svg") !important;
      }

      .campaign-management-icon-minimized {
        height: 19px;
        width: 19px;
        margin-right: 0;
      }

      .campaign-management-text {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .campaign-management-text-pressed {
        color: rgba(251, 193, 59, 1) !important;
      }

      .campaign-management-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }
    .campaign-management-container-minimized {
      justify-content: center;
      margin-bottom: 0;
    }

    .observers-container {
      flex-direction: row;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .observers-icon {
        background-image: url("../../../assets/obsemp.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 8px;
        cursor: pointer;
        height: 23px;
        width: 23px;
        position: relative;
        right: 1px;
      }

      .observers-icon-pressed {
        background-image: url("../../../assets/obsemp_1.svg") !important;
      }

      .observers-icon-minimized {
        margin-right: 0;
        height: 35px;
        width: 25px;
      }

      .observers-text {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .observers-text-pressed {
        color: rgba(251, 193, 59, 1) !important;
      }

      .observers-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }
    .observers-container-minimized {
      justify-content: center;
      margin-bottom: 0;
    }

    .logout-container-minimized {
      justify-content: center;
    }

    .logout-container {
      flex-direction: row;
      display: flex;
      align-items: center;

      .logout-icon {
        background-image: url("../../../assets/logout.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 16px;
        cursor: pointer;
        height: 18px;
        width: 18px;
      }

      .logout-icon-minimized {
        margin-right: 0;
      }

      .logout-text {
        font-size: 0.9rem;
        color: rgb(255, 49, 80);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .logout-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }

    .lab-management-container-minimized {
      justify-content: center;
    }

    .lab-management-container {
      flex-direction: row;
      display: flex;
      align-items: center;

      .lab-management-icon {
        background-image: url("../../../assets/lab-icon.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
        cursor: pointer;
        height: 16px;
        width: 16px;
      }

      .lab-management-icon-pressed {
        background-image: url("../../../assets/‏‏lab-icon-active.svg") !important;
      }

      .lab-management-icon-minimized {
        width: 15px;
        height: 20px;
        margin-right: 0;
      }

      .lab-management-text {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        letter-spacing: 0.03em;
        cursor: pointer;
      }

      .lab-management-text-pressed {
        color: rgb(251, 193, 59) !important;
      }

      .lab-management-text-minimized {
        visibility: hidden;
        width: 0;
      }
    }
  }
}
