.b_text-field{
  height: 60px  ;
  .input{
    border: none;
    border-bottom: 1px solid;
    font-size: 1rem;
    background: none;
    width: 200px;
    padding: 5px 0;
    letter-spacing: 0.01em;
  }
}
.b_select{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  .selected-value{

    .value{

    }
  }
}

.b_select_dialog{
  .title{

  }
  .input{
    border-bottom: 1px solid;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 9px;
    width: 176px;
  }
}
.input_title{
  font-weight: 500;
  font-size: 1em;
  color: rgb(5, 22, 64);;
}
.b_title{
  font-weight: bold;
  font-size: 0.95em;
  color: blue;
}

.b-groups-select{
  .b_title{}

  .selected-value{
    position: relative;
    height: 30px;
    &:focus{
      outline:none
  }

    .value{
      border: none;
      border-bottom: 1px solid;
      font-size: 1.05rem;
      padding: 0px 0px 7px 0px;
      margin-top: 3px;
      background: none;
      width: 176px;
      height: 20px;
      cursor: pointer;
      &:hover{
        border-bottom: 2px solid;
      }
    }
    .disable{
      border-color: #d8d0d0;
      pointer-events: none;
    }
    .groups {
      opacity: 1;
      min-width: 176px;
      transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform-origin: 176px 0px;
      position: absolute;
      z-index: 1000;
      width: 176px;
      background: white;
      right: 0;
      top: 30px;
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      border-radius: 4px;
      padding: 7px 0;
      text-indent: 10px;
      font-size: 1.1em;
      box-sizing: border-box;

      .group-options {

        .group-title {
          position: relative;

          &:hover{
            background: #ececec;
            .title{
              //font-weight: bold;
            }
            .options {
              display: block;
              position: absolute;
              width: 176px;
              background: white;
              right: 174px;
              top: 0;
              padding: 3px 10px;
              font-size: 1.1em;
              box-sizing: border-box;
              box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
              max-height: 430px;
              overflow-y: auto;
              z-index: 10000;

              .option {
                padding: 3px 0px;
                box-sizing: content-box;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
                text-align: left;
                direction: ltr;
              }
            }
          }
          .title{
            //justify-content: space-between;
            height: 35px;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            cursor: default;
            //&:after{
            //  content: '>';
            //}
          }

          .options{
            display: none;
          }
        }
      }
    }
  }

}

.image-dialog{
  display: flex;
  flex-direction: column;
  align-items: center;
  .exit-dialog{
    align-self: end;
    cursor: pointer;
    color: grey;
    margin-left: 5px;
    margin-top: 5px;
  }
  .image-box{
    height: 600px;
    width: auto;
  }
}
