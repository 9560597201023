.employee-template {
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  width: 670px;

  .close-template {
    cursor: pointer;
    color: red;
    font-size: 1.5em;
  }

  .template-form {
    padding: 10px;

    .template-header {
      font-size: 1.3em;
      font-weight: bold;
      margin-bottom: 14px;
      color: #2d3247;
    }

    .template-fields {
      height: 220px;
      display: flex;
      justify-content: space-between;
      margin-top: 25px;

      .main-fields {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .second-fields {
        width: 250px;

        .field-worker-tem {
          display: flex;
          flex-direction: column;
        }

        .image-analyst-tem {
        }

        .back-office-tem {

        }
      }
    }
  }
  .done-btn {
    cursor: pointer;
    padding: 2px 25px;
       align-self: flex-end;
    border-radius: 6px;
  }

  .active-done-btn {
    cursor: pointer;
    color: #ffc500;
    border: 1px solid #ffc500;
  }

  .disable-btn {
    pointer-events: none;
    color: #808080;
    border: 1px solid #808080;

  }
}
