.add-villages-dialog{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  font-size: 1.2em;
  justify-content: space-around;
  height: 370px;
  position: absolute;
  background: white;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #212529;

  .exit{
    color: grey;
    align-self: flex-end;
    cursor: pointer;
    font-size: 1.4em;
  }
  .file-header{
    margin-top: 25px;
    font-weight: bold;
  }

  .logs-email{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
  }
  .upload-file{
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 20px;
    margin-top: 20px;
    color: orange;
    border: 1px solid;
    align-self: center;
  }
  .no-file{
   color: grey;
    pointer-events: none;
  }
}

