.villages-table{
  padding: 10px;
  .village-table-comp{
    margin-top: 15px;
  }
  .col{
    padding: 0px 0 !important;
    cursor: pointer;
    width: 120px !important;
  }
  .col-bold{
    font-weight: bold;
    color: #3c67f8;
  }
  .col-link{
    font-weight: bold;
    color: #4fa9de;
    text-decoration: underline;
  }


}
.mini-villages-table{
  height: 300px;
  overflow: auto;
  .col{
    padding: 0 !important;
    cursor: pointer;
    width: 120px !important;
  }.small-col{
    padding: 0 0 !important;
    cursor: pointer;
    width: 90px !important;
  }
}
.table-row {
  height: 50px;
  &:hover {
    background: #f4f4f4;
  }
}
