.map-page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: -webkit-fill-available;

  .switch-style-switch {
    position: absolute;
    z-index: 3;
    background: white;
    border-radius: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 7px 15px 0;
    right: 350px;
  }
  .filter-map-by-date {
    position: absolute;
    z-index: 5;
    background: white;
    border-radius: 10px;
    top: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    padding: 0px 0px 0px;
    right: 350px;
    .reset-filter {
      margin-left: -10px;
      margin-right: 10px;
      font-size: 1.5em;
      cursor: pointer;
      z-index: 10;
    }
  }
  .tabs-layout {
    width: 78%;
    position: absolute;
    z-index: 3;
    align-self: center;
    max-width: 820px;
    top: 0;
    left: 45%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 40px;
    > div:first-child {
      // modifies the background color
      background-color: transparent !important;
      border-radius: 0px 0px 11px 11px;
      //overflow: hidden;
      height: 100%;
      align-items: center;
    }
    button {
      font-size: 0.9vw !important;
      padding: 0 7px !important;
    }
    .filter-tab {
      color: rgb(45, 50, 71) !important;
      background-color: rgb(249, 249, 249) !important;
      text-transform: unset !important;
      font-weight: 400 !important;
    }
    .active-tab {
      color: rgb(255, 255, 255) !important;
      background-color: rgb(251, 193, 59) !important;
      font-weight: 500 !important;
    }
    .active-tab:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top-color: rgb(251, 193, 59);
      border-bottom: 0;
      margin-left: -8px;
      margin-bottom: -8px;
    }
    .team-leader-keys {
      background: white;
      width: 35%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 13px;
      padding: 10px;
      border-radius: 5px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;

      .leader-key {
        display: flex;
        .map-icon-image {
          height: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .map-container {
    height: 100%;
    width: 100%;
    display: flex;

    .right-sidebar-container {
      position: absolute;
      right: 0;
      min-width: 337px;
      z-index: 4;
      width: 320px;
      background-color: #ffffff;
      overflow: auto;
      height: 100vh;

      .task-review {
        background: #fbc13b;
        color: black;
        padding: 10px 0;

        .task-review-caption {
          font-weight: bold;
        }
      }

      .right-sidebar {
        padding-top: 10px;
        padding-left: 25px;

        .export-data {
          margin-right: 10px;
          text-align: end;
          color: rgba(0, 145, 255, 1);
          cursor: pointer;
          margin-bottom: 10px;

          &:before {
            content: url("../../../assets/share.svg");
            vertical-align: middle;
            margin-right: 2px;
          }
        }
        .villages-table-btn {
          margin-right: 10px;
          text-align: end;
          color: rgba(0, 145, 255, 1);
          cursor: pointer;
          margin-bottom: 10px;

          &:before {
            content: url("../../../assets/data-table.png");
            vertical-align: middle;
            margin-right: 2px;
          }
        }

        .r-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          .campaigns-actions {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
          .select-team-leader {
            margin: 20px 0;
          }
          .village-summary-data {
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            color: #3e3d3d;
            align-items: baseline;

            .summary-line {
              padding: 4px;
              border-left: 6px solid;
              margin-bottom: 10px;
              font-size: 1.1em;
            }
          }
          .v-contain,
          .house-contain,
          .chunk-contain {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          .txt-container {
            display: flex;
            flex-direction: column;
            padding-right: 28px;
            .camp-name {
              font-size: 22px;
              color: #2d3247;
              text-align: left;
              letter-spacing: 0.01em;
              font-weight: 900;
            }
            .villages-count {
              padding-top: 3px;
              text-align: left;
              display: flex;
              span {
                letter-spacing: 0.03em;
                font-size: 14px;
                color: #666565;
                padding: 0 5px;
              }
            }
            .sum-scanned {
              padding-top: 3px;
              font-size: 14px;
              color: rgba(126, 211, 33, 1);
              text-align: left;
              letter-spacing: 0.03em;
            }
            .water-sources-count {
              padding-top: 3px;
              font-size: 14px;
              text-align: left;
              letter-spacing: 0.04em;
              color: #697191;
            }
            .sprayed-count {
              padding-top: 3px;
              font-size: 14px;
              font-weight: 300;
              color: rgba(65, 233, 146, 1);
              text-align: left;
              letter-spacing: 0.04em;
            }
          }
          .search-village {
            display: contents;
            .search-container {
              display: flex;
              padding-right: 14px;
              top: -20px;
              position: relative;
              .search-icon {
                position: relative;
                right: 20px;
                top: 35px;
                height: 19px;
                width: 19px;
              }
            }
            .search-headers {
              display: flex;
              font-size: 10px;
              padding-top: 10px;
              font-weight: 600;
              padding-right: 25px;
              .al {
                text-align: left;
              }
              div {
                color: rgb(45, 50, 71);
                padding: 0px;
              }
              .header-status {
                text-align: left;
                padding-left: 18px;
              }
              .header-village-name {
              }
              .header-last-visited {
                text-align: left;
                margin-left: 8px;
              }
            }
            .search-content {
              overflow: auto;
              margin-bottom: 10px;
              max-height: 200px;
              .content-item {
                background-color: #ffffff;
                min-height: 50px;
                display: flex;
                cursor: pointer;
                margin-right: 25px;
                border-bottom: solid 1px rgba(218, 214, 214, 0.8);
                padding: 8px 0;
                .first-a {
                  text-align: left;
                  display: flex;
                  padding: 0px !important;
                  align-items: center;
                  .village-name {
                    color: rgb(102, 101, 101);
                    letter-spacing: 0.03em;
                    font-size: 12px;
                    height: fit-content;
                    padding-right: 15px;
                    hyphens: auto;
                  }
                  img {
                    margin-left: 15px;
                  }
                }
                .village-date-container {
                  text-align: left;
                  padding-right: 0px;
                  padding-left: 0px;
                  align-items: center;
                  display: flex;
                  .village-time-updated {
                    color: rgb(102, 101, 101);
                    letter-spacing: 0.03em;
                    font-size: 12px;
                  }
                }
                .village-status-container {
                  padding-right: 0px;
                  padding-left: 20px;
                  text-align: left;
                  align-items: center;
                  display: flex;
                  .village-status {
                    color: #94c6ff;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }
              .inactive-item {
                //cursor: default !important;
                .village-name,
                .village-time-updated,
                .village-status {
                  color: rgb(186, 186, 186) !important;
                }
                .col-456 {
                  flex: 0 0 49.333333%;
                  max-width: 24.333333%;
                }
              }
            }
          }
          .new-village-btn {
            align-self: flex-end;
            margin-top: auto;
            margin-bottom: 10px;
            margin-right: 15px;
            display: flex;
            align-items: center;

            .open-upload-village-btn {
              cursor: pointer;
              margin-left: 10px;
              color: darkgrey;
              padding: 7px;
              font-weight: 500;
              border: 1px solid;
              border-radius: 10px;
            }
          }

          .ws-back-btn,
          .chunk-back-btn,
          .house-back-btn,
          .village-back-btn {
            color: #a3a3a3;
            display: flex;
            .back-icon {
              cursor: pointer;
              margin-top: 3px;
            }
            .back-text {
              padding-left: 4px;
              position: relative;
              top: 5px;
              font-size: 15px;
              cursor: pointer;
            }
          }
          .ws-name {
            font-size: 19px;
            font-weight: bold;
            color: #3e3d3d;
            text-align: left;
            letter-spacing: 0.01em;
            padding-top: 25px;
          }
          .v-details {
            padding-top: 25px;
            display: flex;
            justify-content: space-between;
            .vd-l-side {
              display: flex;
              .v-name {
                font-size: 19px;
                font-weight: bold;
                color: rgb(62, 61, 61);
                text-align: left;
                letter-spacing: 0.01em;
                border: none;
                border-bottom: 1px solid white;
                width: auto;

                &:focus {
                  outline: none;
                  border-bottom: 1px solid;
                  font-weight: 300;
                }
              }
              .v-status {
                font-size: 12px;
                color: #57a3fa;
                text-align: left;
                letter-spacing: 0.03em;
                font-weight: bold;
                line-height: 2.6;
                padding-left: 7px;
              }
            }
            .vd-r-side {
              .vd-send-mail {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 60px;
                height: 40px;
                img {
                  cursor: pointer;
                }
              }
            }
          }
          .chunk-details {
            .chunk-name {
              font-size: 19px;
              font-weight: bold;
              color: #3e3d3d;
              text-align: left;
              letter-spacing: 0.01em;
              padding-top: 30px;
            }
            .chunk-long-lat {
              color: rgb(62, 61, 61);
              display: flex;
              padding-top: 15px;
              .chunk-long-lat-icon {
                margin-right: 5px;
              }
              span {
                font-size: 14px;
                line-height: 1.9;
              }
            }
          }
          .more-info {
            font-size: 14px;
            color: #ffc015;
            text-align: left;
            letter-spacing: 0.03em;
            font-weight: 600;
            padding-top: 2px;
            padding-bottom: 15px;
          }
          .area-data-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            padding: 15px 0;
            //> div > span {
            //  font-size: 1.05rem;
            //}
            .area-data-r {
              display: flex;
              flex-direction: column;
              .area-chunks {
                position: relative;
                right: 3px;
                .area-chunks-shape {
                  height: 13px;
                  width: 13px;
                  border: 1px solid rgba(102, 101, 101, 1);
                  border-radius: 2px;
                }
              }
              .area-core-belt {
                .area-core-belt-shape {
                  height: 12px;
                  width: 7px;
                  background-color: rgba(102, 101, 101, 1);
                  border-radius: 1px;
                }
              }
            }
            .area-ws {
              height: 17px;
            }
            .area-item {
              display: flex;
              align-items: center;
              line-height: 1;
              span {
                font-size: 16.5px;
                color: rgba(102, 101, 101, 1);
                text-align: left;
                letter-spacing: 0.03em;
                padding-left: 5px;
                white-space: nowrap;
              }
            }
            .area-sampled-top {
              position: relative;
              right: 2px;
            }
          }
          
          .reviewing-task {
            position: relative; /* Add position relative */
            padding: 10px;
            border: 1px solid;
            border-image: linear-gradient(to bottom, rgb(255, 179, 0) 0%, rgb(255, 204, 0) 50%, rgb(201, 128, 0) 100%);
            border-image-slice: 1;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          }
          
          .tl-data-container {
            .section-h {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .section-h-l {
                font-weight: 500;
              }
              .section-h-r {
                font-size: 10px;
                color: rgb(60, 103, 248);
                text-align: left;
                letter-spacing: 0.03em;
                font-weight: 600;
                cursor: pointer;
                text-decoration: underline;
              }
            }
            .section {
              font-size: 16px;
              color: rgba(62, 61, 61, 1);
              text-align: left;
              letter-spacing: 0.04em;
            }
            .section-info {
              font-weight: 300;
              display: flex;
            }
          }
          .scanner-data-container {
            padding-top: 5vw;
            .section.section-h-l {
              font-weight: 500;
            }
            .chunk-approved {
              font-size: 12px;
              font-weight: 500;
              font-style: italic;
              color: #08d9dc;
              text-align: left;
              letter-spacing: 0.03em;
            }
          }
          .tl-edit-container {
            .section-h {
              font-size: 16px;
              color: rgba(60, 103, 248, 1);
              text-align: left;
              letter-spacing: 0.04em;
              font-weight: 500;
              cursor: pointer;
            }
            .section-list {
              border: 1px solid rgba(45, 50, 71, 1);
              border-radius: 6px;
              overflow-y: auto;
              height: 154px;
              width: 262px;
            }
          }
          .data-from-field {
            padding-top: 30px;
            display: flex;
            flex-direction: column;
            color: rgba(102, 101, 101, 1);
            .sprayer-phone-number {
              text-align: left;
            }
            .data-item {
              display: flex;
              align-items: center;
              line-height: 1;
              .data-shape-1 {
                height: 19px;
                width: 7px;
                border-radius: 1px;
              }
              .data-shape-2 {
                height: 7px;
                width: 7px;
                border-radius: 4px;
              }
              .data-shape-1.azure {
                background-color: rgb(50, 197, 255);
              }
              .data-shape-1.purple {
                background-color: rgb(186, 134, 233);
              }
              .data-shape-1.yellow {
                background-color: rgb(255, 234, 18);
              }
              .data-shape-2.green {
                background-color: rgb(65, 233, 146);
              }
              .data-shape-2.orange {
                background-color: rgb(245, 128, 35);
              }
              .data-shape-2.yellow {
                background-color: rgb(255, 234, 18);
              }
              span {
                // font-size: 15px;
                color: rgba(102, 101, 101, 1);
                text-align: left;
                letter-spacing: 0.03em;
                padding-left: 5px;
              }
            }
            .data-item-2 {
              position: relative;
              top: -4px;
            }
            .data-item-2-icon {
              position: relative;
              right: 10px;
            }
          }
          .chunk-data-from-field {
            height: 100%;
            justify-content: flex-end;
            padding-bottom: 130px;
          }
          .village-operatinos-bar,
          .chunk-operatinos-bar {
            background-color: #ffffff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
            width: 100%;
            position: absolute;
            bottom: 0px;
            height: 69px;
            right: 0px;
            align-items: center;
            display: flex;
            img {
              margin-left: 20px;
              cursor: pointer;
            }
            .village-menu,
            .chunk-menu {
              position: absolute;
              width: 261px;
              background-color: rgba(255, 255, 255, 1);
              border: 1px solid rgba(45, 50, 71, 0.36);
              border-radius: 6px;
              padding: 15px;
              bottom: 43px;
              left: 22px;
              .oper {
                font-size: 16px;
                color: rgba(45, 50, 71, 1);
                text-align: left;
                letter-spacing: 0.03em;
                line-height: 25px;
                cursor: pointer;
                width: fit-content;
              }
              .splitter {
                border-bottom: 1px solid rgba(45, 50, 71, 1);
                width: 100%;
                margin: 15px 0px;
              }
            }
          }

          .ws-description {
            color: #3e3d3d;
            text-align: left;
            margin: 30px 0;
            .coordinates-icon {
              margin-right: 5px;
              height: 18px;
            }
            .water-bubble-icon {
              margin-right: 5px;
              height: 24px;
            }
            .deleted-drone-ws {
              margin-top: 5px;
              color: red;
              font-weight: 500;
            }
          }
          .ws-id,
          .ws-created-by {
            color: black;
            text-align: left;
            margin-top: 5px;
          }
          .ws-created,
          .ws-last-visit {
            color: #4f6fcd;
            font-size: 13px;
            font-weight: 500;
            text-align: left;
          }
          .gray-line {
            border-bottom: 0.7px solid lightgray;
            margin-right: 15px;
            padding-top: 25px;
          }
          .ws-picture {
            cursor: pointer;
            img {
              max-width: 200px;
              position: relative;
              right: 7px;
              max-height: 170px;
              border-radius: 10px;
            }
          }
          .ws-not-found {
            font-size: 14px;
            color: #c38b08;
            text-align: left;
            letter-spacing: 0.03em;
            padding-top: 10px;
            img {
              width: 24px;
              padding-right: 5px;
              position: relative;
              top: -2px;
            }
          }
          .ws-sprayed {
            letter-spacing: 0.03em;
            padding-top: 4px;
          }
          .ws-sampled {
            letter-spacing: 0.03em;
            padding-top: 4px;
            .color-lust {
              color: #f52323 !important;
            }
          }
          .ws-larva-positive {
            padding-top: 3px;
            letter-spacing: 0.03em;
          }
          .ws-pupae {
            padding-top: 3px;
            color: rgba(245, 128, 35, 1);
            text-align: left;
            letter-spacing: 0.03em;
          }
        }
        .r-container.village {
          padding-right: 15px;
        }

        .r-container.chunk-info {
          padding-right: 10px;
        }
      }
    }
    .pddt10 {
      padding-top: 10px !important;
    }
    .map-layout-container {
      position: relative;
      width: 100%;
      .map-layout {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
      }
      .map-layout-create-mode {
        z-index: 999;
      }
    }
  }
}

.b-waight {
  color: rgb(105, 113, 145);
  font-weight: 400;
}

.bb-waight {
  font-size: 13px;
  font-weight: 500;
  color: rgba(105, 113, 145, 1);
  text-align: left;
  letter-spacing: 0.04em;
  line-height: 24px;
}

.pddt5 {
  padding-top: 5px;
}

.pddr5 {
  padding-right: 6px;
}

.pddr10 {
  padding-right: 10px;
}

.pddr15 {
  padding-right: 10px;
}

.display-zoom-level {
  position: absolute;
  z-index: 3;
  background-color: #f9f9f9;
  border-radius: 5px;
  color: #000000;
  font-size: 12px;
  line-height: 0.8;
  bottom: 25px;
  right: 400px;
  display: flex;
  justify-content: space-around;
  width: 230px;
  padding: 8px 10px;
  box-sizing: content-box;
  font-weight: 400;

  .zoom {
    &:before {
      font-weight: bold;
      content: "zoom:";
      margin-right: 2px;
    }
  }
  .display-long {
    &:before {
      font-weight: bold;
      content: "lng:";
      margin-right: 2px;
    }
  }
  .display-lat {
    &:before {
      font-weight: bold;
      content: "lat:";
      margin-right: 2px;
    }
  }
}
.map-layout-create-mode {
  .display-zoom-level {
    right: 60px;
  }
}

.vertical-dotted-line {
  height: 30px;
  position: relative;
  left: 3px;
  width: fit-content;
  margin: 2px 0px;
}

.vertical-dotted-line-1 {
  border-left: 1px dotted rgb(102, 101, 101);
}

.vertical-dotted-line-2 {
  border-left: 1px dotted rgb(247, 181, 0);
}

.vertical-dotted-line-3 {
  border-left: 1px dotted rgb(125, 211, 42);
}

.vertical-dotted-line.azure {
  border-left: 1px dotted rgb(50, 197, 255);
}

.vertical-dotted-line.purple {
  border-left: 1px dotted rgb(186, 134, 233);
}

.vertical-dotted-line.yellow {
  border-left: 1px dotted rgb(255, 234, 18);
}

.vertical-dotted-line.green {
  border-left: 1px dotted rgb(65, 233, 146);
}

.vertical-dotted-line.orange {
  border-left: 1px dotted #f58023;
}

.area-scanned-icon {
  background: orange;
}
.area-scanned-icon,
.area-sampled-icon,
.area-sprayed-icon {
  height: 12px;
  width: 7px;
  border-radius: 1px;
}

.area-scanned-icon-1 {
  background-color: rgb(102, 101, 101);
}

.area-scanned-icon-2 {
  background-color: rgb(247, 181, 0);
}

.area-scanned-icon-3 {
  background-color: rgb(125, 211, 42);
}

.area-sprayed-icon-1 {
  background-color: #41e992;
}
.area-trap-icon-1 {
  background-color: #ba86e9;
}
.area-issue-icon-1 {
  background-color: #ef3b3b;
}

.traps-count {
  //width: 100px;
}

.area-sampled-icon-1 {
  background-color: #f58023;
}

.pddt10 {
  padding-top: 10px;
}

.pddt5 {
  padding-top: 5px;
}

.flx-col-justify-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#ws-selector {
  max-width: 262px;
  top: 1.5rem;
  left: 220px;
  display: flex;
  align-items: center;
  .dropdown-btn {
    height: 42px;
    width: 262px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 6px;
    color: #000000;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0.4rem 0 1rem;
    cursor: pointer;
    span {
      font-size: 16px;
      color: #666565;
      letter-spacing: 0.03em;
    }
  }
  .dropdown-menu {
    margin: 0px !important;
    top: calc(100% - 5px);
    width: 100%;
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #000000;
    border-top: unset;
    padding-top: 0px;
  }
  .dropdown-item {
    font-size: 14px;
    font-weight: 300;
    color: #474747;
    letter-spacing: 0.03em;
    padding: 0.5rem 2rem;
    cursor: pointer;
  }
  .dropdown-item:active {
    // background-color: #fbc13b !important;
  }
  .dropdown-item.selected-ws-item {
    font-weight: 500 !important;
  }
}

.txt-search-field {
  width: 100% !important;
}
.mapboxgl-ctrl-bar {
  .mapboxgl-ctrl-bottom-right {
    right: 0 !important;
    bottom: 80px !important;
  }
}
.house-details {
  .house-name {
    font-size: 19px;
    font-weight: bold;
    color: #3e3d3d;
    text-align: left;
    letter-spacing: 0.01em;
    padding-top: 30px;
  }
  .house-long-lat {
    color: rgb(62, 61, 61);
    display: flex;
    padding-top: 15px;
    .chunk-long-lat-icon {
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      line-height: 1.9;
    }
  }
  .house-sidebar-property {
    color: rgb(62, 61, 61);
    display: flex;
    padding-top: 15px;
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
}
