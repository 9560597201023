.yes-or-no-template {
  .modal-title {
    padding: 25px 0px 15px 45px;
    font-weight: bold;
  }

  .modal-description {
    height: 93px;
    // width: 162px;
    font-size: 16px;
    color: rgb(14, 3, 73);
    text-align: center;
    letter-spacing: 0.03em;
    line-height: 31px;
    margin: 0 auto;
  }

  .modal-btns {
    display: flex;
    justify-content: flex-end;
    div {
      font-size: 16px;
      font-weight: 500;
      color: rgb(45, 50, 71);
      text-align: left;
      letter-spacing: 0.03em;
    }
  }

  .yes-or-no-modal-contant {
    position: absolute;
    flex: 0 0 50%;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 14px;
    // padding: 45px 20px 30px 40px;
    max-width: 380px;
    width: 100%;
    .modal-title {
      font-size: 25px;
      text-align: center;
      padding: 0px !important;
    }
  }
}
