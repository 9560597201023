.server-dialog {
  font-weight: bold;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 15px 30px 20px;
  box-sizing: border-box;

  .headline-dialog {
    font-size: 25px;
  }

  .message-dialog {
    font-size: 16px;
    margin:10px 0;
  }

  .btn-accept-dialog {
    color: #fbc13b;
    height: 33px;
    width: 109px;
    border: 1px solid #fbc13b;
    border-radius: 4px;
    background: white;
    margin-left: auto;
    margin-top: 30px;
    &:hover{
      background: #f3f3f3;
    }
    &:focus{
      outline: none;

    }
  }
}
.spinner-background{
  background: rgba(0,0,0,0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 299;
}
.spinner-spinner{
  position: absolute;
  top: 42%;
  left: 42%;
  z-index: 300;
  transform: translate(-50%, -50%);
  animation: mosquitoSpinner 3s infinite;
  animation-timing-function: linear;
}

@keyframes mosquitoSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg)
  }
}
