//$blue-color: rgba(19, 17, 55, 1);

.login-page {
  height: 100%;
  display: flex;
  justify-items: center;
  width: 479px;
  padding: 0;

  .right-section {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.03em;

    .login-frm {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 0px 20px 0px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 14px;
      .frm-header {
        font-size: 35px;
        font-weight: 500;
        color: #2d3247;
        padding-bottom: 30px;
      }
      .frm-header-forgot-pass{
        padding-bottom: 0px !important;
      }
      .txt-field,
      .txt-field-password {
        position: relative;
        width: 100% !important;
        max-width: 311px;
        height: 40px !important;
        display: block !important;
        text-align: center;
        background-color: rgb(243, 243, 243) !important;
        border: 1px solid #979797 !important;
        border-radius: 6px;
        font-weight: normal;
        margin-bottom: 40px;
      }

      .frm-btn {
        cursor: pointer;
        width: 100% !important;
        max-width: 311px;
        background-color: #fbc13b;
        font-weight: normal;
        height: 40px;
        border-radius: 8px;
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 0.06em;
        line-height: 2.3;
      }

      .forgot-pass {
        cursor: pointer;
        width: 100% !important;
        max-width: 311px;
        text-align: right;
        font-size: 16px;
        color: rgba(79, 111, 205, 1);
        letter-spacing: 0.06em;
        text-decoration: underline;
        text-underline: rgba(79, 111, 205, 1);
        font-weight: normal;
        padding-top: 10px;
      }
      .forgot-pass-massage {
        font-size: 15px;
        color: #2d3247;
        text-align: center;
        letter-spacing: 0.04em;
        line-height: 1.5;
        font-weight: 500;
        width: 100% !important;
        max-width: 325px;
        margin: 15px 0px 40px 0px;
      }
      .forgot-pass-massage-sent {
        font-size: 24px;
        color: #2d3247;
        text-align: center;
        letter-spacing: 0.04em;
        font-weight: bold;
        padding-bottom: 30px;
      }
    }
  }
}

