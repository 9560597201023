.data-exporting-dialog {
  width: 100%;
  padding: 30px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  .close-data-export-dialog {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;

  }

  .close-data-export-dialog path {
    fill: #000000;
  }

  .main-export {
    width: 75%;
    margin: 15px 0px 35px;
    line-height: 30px;

    .data-exporting-header {
      font-size: 20px;
      font-weight: 500;
      color: rgba(62, 61, 61, 1);
      margin-bottom: 15px;
      &:before {
        content: 'Data exporting for campaign "';
      }

      &:after {
        content: '"';
      }
    }


    .data-exporting-orders {
      font-size: 16px;
      font-weight: bold;
      color: rgba(62, 61, 61, 1);
      margin-bottom: 15px;
      border: none;
      border-bottom: 1px solid;
    }

    .data-exporting-date {
      font-size: 16px;
      font-weight: 500;
      color: rgba(247, 181, 0, 1);
      position: relative;
      cursor: pointer;
      width: max-content;

      &:before {
        content: 'Date ';
        font-size: 16px;
        color: rgba(77, 77, 77, 1);
        font-weight: bold;
        margin-right: 10px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #f7b500;
        position: absolute;
        top: calc(50% - 4px);
        right: -15px;
      }
    }
  }

  .export-data-button {
    color: #fbc13b;
    height: 33px;
    width: 109px;
    border: 1px solid #fbc13b;
    border-radius: 4px;
    background: white;
    margin-left: auto;

    &:hover {
      background: #f3f3f3;
    }

    &:focus {
      outline: none;
    }
  }
}

.Cal__Today__chevron {
  display: none !important;
}

.Cal__Weekdays__day {
  padding: 10px 10px !important;
}

.Cal__Header__root {
  min-height: auto !important;
  padding: 10px 10px 0px !important;
  background-color: #FFC015 !important;
}

.Cal__Header__date {
  font-size: 20px !important;
}

.Cal__Weekdays__root {
  background: #FFD461 !important;
}

.Cal__Day__selection {
  background-color: #FFC015 !important;
  width: 46px !important;
  height: 46px !important;
  margin-top: -23px !important;
  margin-left: -23px !important;
}

.calendar {
  position: fixed;
}
